<template>
  <div class="list flex-column-center-center">
    <router-link to="/login">登录</router-link>
    <router-link to="/member-join">入会申请</router-link>
    <router-link to="/member-list">会员风采</router-link>
    <router-link to="/member-detail">会员风采详情</router-link>
    <router-link to="/member-communication">会员信息交流</router-link>
    <router-link to="/member-exchange-info-detail">会员交流信息</router-link>
    <router-link to="/add-member-exchange-info">发布信息</router-link>
    <router-link to="/member-news">会员动态</router-link>
    <router-link to="/member-news-detail">会员动态详情</router-link>
    <router-link to="/industry-list">产业动态</router-link>
    <router-link to="/industry-detail">产业动态详情</router-link>
    <router-link to="/policy-list">政策信息</router-link>
    <router-link to="/policy-detail">政策信息详情</router-link>
    <router-link to="/association-list">协会动态</router-link>
    <router-link to="/association-detail">协会动态详情</router-link>
    <router-link to="/activity-list">文创活动</router-link>
    <router-link to="/activity-detail">文创活动详情</router-link>
    <router-link to="/activity-signup">文创活动报名</router-link>
    <router-link to="/creativity-list">创意推荐</router-link>
    <router-link to="/creativity-detail">创意推荐详情</router-link>
    <router-link to="/personal-center">个人中心</router-link>
    <router-link to="/user/info">个人信息</router-link>
    <router-link to="/user/member">会员信息</router-link>
    <router-link to="/user/certificate">相关证书</router-link>
    <router-link to="/resource-list">下载中心</router-link>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'Temp'
}
</script>
<style>
.list a{
  color: #42b983;
}
.list a + a {
  margin-top: 10px;
}
</style>
